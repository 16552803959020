<template>
  <!-- 
    This section for navbar in the top that include fancy logo, search, user info and log out button.
    Bootstrap navbar features are used.
    me class important for right margin.
-->

  <nav class="navbar fixed-top" id="topbar">
    <div class="imageContainer" style="margin-left: 20px">
      <router-link to="/home">
        <img
          src="../fancy-logo.png"
          class="img-fluid navbar-brand ml-4"
          alt="Responsive image"
        />
      </router-link>
    </div>

    <form class="d-flex formContainer">
      <!-- <span class="p-float-label  me-5" id="searchContainer"> -->
      <div
        class="mr-sm-2 selectContainer"
        style="margin-right: 20px; width: 200px"
      >
        <v-select
          id="search"
          :options="filteredProduct"
          placeholder="Search"
          label="name"
          :filterable="false"
          @search="searchProduct"
          v-model="selectedProduct"
          @option:selected="searchSelect"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes"></span>
          </template>
        </v-select>
      </div>

      <!-- <AutoComplete 
            class="mr-sm-2"
            id="search" 
            v-model="selectedProduct" 
            @item-select=" searchSelect($event)" 
            :suggestions="filteredProduct" 
            @complete="searchProduct($event)" 
            
            field="name" /> -->
      <!-- <label for="search">Search</label>
    </span> -->

      <ul class="list-unstyled my-4 me-3 my-sm-0">
        <li>{{ username }}</li>
        <li>{{ store }}</li>
      </ul>

      <button
        @click="logOut"
        class="btn btn-outline-dark my-4 me-3 my-sm-0"
        type="submit"
      >
        Log Out
      </button>
    </form>
  </nav>

  <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top " id="topbar" >
  <div class="row">
    <div class="col-9 ml-3">
        <router-link to="/home">
          <img src="../fancy-logo.png" class="img-fluid navbar-brand " alt="Responsive image">
      </router-link>
    </div>
  </div>

</nav> -->

  <!-- 
    This section include left menu.
    This component created with MenuItem.vue so if you want any change please check also MenuItem.vue
-->

  <div class="sidebar">
    <div class="mt-1">
      <router-link to="/merchant-products"> Merchant Dashboard</router-link>
    </div>
    <div v-for="title in menuTree" :key="title.label">
      <div class="div" v-if="title.permission === true">
        <a class="text-center side-title" :style="{ color: 'white' }"
          ><strong>{{ title.label }}</strong></a
        >
        <span v-for="item in title.children" :key="item.label">
          <router-link
            v-if="item.permission === true && item.isAdminPanel != true"
            :to="{ path: item.to }"
            :class="[this.$route.path == item.to ? 'active' : null]"
          >
            &#8226;{{ item.label }}
          </router-link>

          <a
            v-if="item.permission === true && item.isAdminPanel === true"
            href="http://52.21.210.70:3000/admin/"
            target="_blank"
          >
            &#8226;Admin Panel
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import { getAPI } from "@/global/callAPI";
import store from "@/store";

export default {
  name: "recursive-menu",
  data: () => ({
    username: store.state.username,
    store: store.state.store,
    selectedProduct: null,
    filteredProduct: [],
    smallMenu: false,
    showTransferError: false,
    transfer_number: null,
    menuTree: [],
    page_permissions: JSON.parse(localStorage.getItem("page_permissions")),
  }),
  components: {},
  created() {
    getAPI("transfer-control").then((res) => {
      const transfer_number = res.data["transfer_number"];
      if (transfer_number > 0) {
        this.showTransferError = true;
        this.transfer_number = transfer_number;
        // this.$toast.add({severity:'error', summary: 'Awaiting Transfers!', detail:`Your store have ${transfer_number} awaiting transfers. Please complete transfers. `});
        toastTransfer
          .fire({
            icon: "warning",
            title: "Awaiting Transfers/Orders!",
            text: `Your store have ${transfer_number} awaiting transfers/orders. `,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/transfers");
              // location = "/transfers"
            }
          });
      }
    });

    this.menuTree = [
      {
        label: "Dashboard",
        icon: "groups",
        // backColor:"white",
        permission: this.hasPermission(["seller", "limited_seller", "office"]),
        children: [
          {
            label: "Dashboard",
            to: "/dashboard",
            permission: this.hasPermission([
              "seller",
              "limited_seller",
              "office",
            ]),
          },
        ],
      },
      {
        label: "Customers",
        icon: "groups",
        // backColor:"white",
        permission: this.hasPermission(),
        children: [
          {
            label: "New Customer",
            to: "/new-customer",
            permission: this.hasPermission(),
          },
          {
            label: "Customer List",
            to: "/customers",
            permission: this.hasPermission(),
          },
        ],
      },
      {
        label: "Sales",
        icon: "shopping_cart",
        color: "#F57600",
        permission: this.hasPermission(),
        children: [
          {
            label: "New Sale",
            to: "/new-sales",
            permission: this.hasPermission(),
            color: "#FBC896",
          },
          {
            label: "Sales List",
            to: "/sales",
            permission: this.hasPermission(),
            color: "#FBC896",
          },
          {
            label: "Sale Products",
            to: "/sale-products",
            permission: this.hasPermission(),
            color: "#FBC896",
          },
          {
            label: "Return Sales",
            to: "/return-sales",
            permission: this.hasPermission(),
            color: "#FBC896",
          },
          {
            label: "Payments",
            to: "/payments",
            permission: this.hasPermission(),
            color: "#FBC896",
          },
          {
            label: "Exchange Rates",
            to: "/exchange-rates",
            permission: this.hasPermission([
              "seller",
              "limited_seller",
              "office",
            ]),
            color: "#FBC896",
          },
        ],
      },
      {
        label: "Products",
        icon: "inventory_2",
        permission: this.hasPermission(),
        color: "#050193",
        children: [
          {
            label: "New Jewel",
            to: "/new-jewel",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#B0BAEA",
          },
          {
            label: "New Watch",
            to: "/new-watch",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#B0BAEA",
          },
          // {label: "Product List", to:"/product-list",permission:this.hasPermission(),},
          {
            label: "Product List",
            to: "/product-search",
            permission: this.hasPermission(),
            color: "#B0BAEA",
          },
          {
            label: "Watch Detail List",
            to: "/watch-detail-list",
            permission: this.hasPermission(),
            color: "#B0BAEA",
          },

          {
            label: "Product Export",
            to: "/product-export",
            permission: this.hasPermission([]),
            color: "#B0BAEA",
          },
          {
            label: "Destroy Product",
            to: "/destroy-product",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#B0BAEA",
          },
          {
            label: "Bulk Product Tag",
            to: "/bulk-product-tag",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#B0BAEA",
          },
          {
            label: "Merchant Settings ",
            to: "/merchant-settings",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#B0BAEA",
          },
        ],
      },

      {
        label: "Catalog",
        icon: "style",
        to: "/catalog",
        color: "#050193",
        permission: this.hasPermission([]),
        children: [
          {
            label: "Catalog List",
            to: "/catalog",
            permission: this.hasPermission([]),
            color: "#B0BAEA",
          },
          {
            label: "Add/Delete Product",
            to: "/add-delete-catalog",
            permission: this.hasPermission(["limited_seller"]),
            color: "#B0BAEA",
          },
        ],
      },

      {
        label: "Transfer/Order",
        icon: "sync",
        color: "#050193",
        permission: this.hasPermission(),
        children: [
          {
            label: "New Transfer/Order",
            to: "/new-transfer",
            permission: this.hasPermission(),
            color: "#B0BAEA",
          },
          {
            label: "Transfer List",
            to: "/transfers",
            permission: this.hasPermission(),
            color: "#B0BAEA",
          },
          {
            label: "Order List",
            to: "/orders",
            permission: this.hasPermission(),
            color: "#B0BAEA",
          },
        ],
      },

      {
        label: "Loans",
        icon: "sync",
        color: "#050193",
        permission: this.hasPermission(),
        children: [
          {
            label: "New Loan",
            to: "/new-loan",
            permission: this.hasPermission(),
            color: "#B0BAEA",
          },
          {
            label: "Loan List",
            to: "/loans",
            permission: this.hasPermission(),
            color: "#B0BAEA",
          },
        ],
      },

      {
        label: "Gems",
        icon: "diamond",
        to: "/gems",
        color: "#009D11",
        permission: this.hasPermission(["limited_seller", "seller"]),
        children: [
          {
            label: "Gem Page",
            to: "/gems",
            permission: this.hasPermission(["limited_seller", "seller"]),
            color: "#BBF0C9",
          },
          {
            label: "Karat Price",
            to: "/karat-price",
            permission: this.hasPermission([
              "limited_seller",
              "seller",
              "office",
            ]),
            color: "#BBF0C9",
          },
        ],
      },

      {
        label: "Accounting",
        icon: "receipt",
        permission: this.hasPermission([]),
        color: "#F5012E",
        children: [
          {
            label: "Accounting Transaction",
            to: "/accounting-transactions",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#FF9B9A",
          },
          {
            label: "Accounting Transaction Summary",
            to: "/accounting-transactions-summary",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#FF9B9A",
          },
          {
            label: "End Of Day Report",
            to: "/end-of-day",
            permission:
              this.hasPermission([]) && this.page_permissions.show_end_of_day,
            color: "#FF9B9A",
          },
          {
            label: "Admin End Of Day",
            to: "/end-of-day-admin",
            permission: this.hasPermission(["seller", "limited_seller"]),
            color: "#FF9B9A",
          },
        ],
      },
      {
        label: "Manufacturers",
        icon: "factory",
        permission: this.hasPermission(["seller", "limited_seller"]),
        children: [
          {
            label: "New Manufacturer",
            to: "/new-manufacturer",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Manufacturer List",
            to: "/manufacturers",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
        ],
      },
      {
        label: "Settings",
        icon: "settings",
        permission: this.hasPermission(),
        children: [
          {
            label: "New User",
            to: "/new-user",
            permission: this.hasPermission([
              "seller",
              "limited_seller",
              "office",
            ]),
          },
          {
            label: "Change Password",
            to: "/change-password",
            permission: this.hasPermission(),
          },
          {
            label: "Add Customer Tag",
            to: "/add-customer-tag",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Sale Tag",
            to: "/add-sale-tag",
            permission: this.hasPermission(),
          },
          {
            label: "Add Product Tag",
            to: "/add-product-tag",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Gem Tag",
            to: "/add-gem-tag",
            permission: this.hasPermission(["limited_seller"]),
          },

          {
            label: "Add Catalog",
            to: "/add-catalog",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Stone Property",
            to: "/add-stone-property",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Jewel Type",
            to: "/add-jewel-type",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Jewel Model",
            to: "/add-jewel-model",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Jewel Product Type",
            to: "/add-jewel-product-type",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Watch Brand",
            to: "/add-watch-brand",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Watch Property",
            to: "/add-watch-property",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Watch Condition",
            to: "/add-watch-condition",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Manufacturer Type",
            to: "/add-manufacturer-type",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Sale Expense Type",
            to: "/add-expense-type",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add End of Day Cost Type",
            to: "/add-cost-type",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add End of Day Cost Group",
            to: "/add-cost-group",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Store",
            to: "/add-store",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Add Transaction Method",
            to: "/add-transaction-method",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Export",
            to: "/export-data",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
          {
            label: "Connect Database",
            to: "/connect-database",
            permission: this.hasPermission([
              "seller",
              "limited_seller",
              "office",
            ]),
          },
          {
            label: "Admin Panel",
            to: "http://52.21.210.70:3000/admin/",
            permission: this.hasPermission([
              "seller",
              "limited_seller",
              "office",
            ]),
            isAdminPanel: true,
          },
          {
            label: "Upload Product Photo",
            to: "/upload-product-photo",
            permission: this.hasPermission(["seller", "limited_seller"]),
          },
        ],
      },
    ];
  },

  methods: {
    searchSelect(event) {
      // this.$router.replace({path:event.value.to});
      console.log(event);
      window.location.replace(event.to);
    },

    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }

      let user_groups = store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    clickPush(item) {
      if (item.to) {
        this.$router.push(item.to);
      }
    },

    logOut() {
      this.$store.dispatch("userLogout").then(() => {
        this.$router.push("/");
      });
    },
    pushLink(data) {
      $router.push({ path: "/products/" + data.id });
    },
    searchProduct(event) {
      this.filteredProduct = [];
      this.selectedProduct = null;
      setTimeout(() => {
        this.search_first(event);
      }, 500);
    },

    search_first(event) {
      let customerData = [];
      let productData = [];

      getAPI("customer-search?limit=5&search=" + event)
        .then((res) => {
          customerData = res.data.results.map(function (item) {
            return {
              name: item.customer_name + " " + item.customer_surname,
              id: item.id,
              to: "/customers/" + item.id,
            };
          });
        })
        .then((res) => {
          getAPI("product-search?limit=10&search=" + event).then((res) => {
            productData = res.data.results.map(function (item) {
              return {
                name: item.reference_no,
                id: item.product_id,
                to: "/products/" + item.product_id,
              };
            });
            this.filteredProduct = customerData.concat(productData);
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sidebar.css";

.img-fluid {
  width: 100px;
  // margin-left: 1%;
}

.bar {
  position: fixed;
}

#searchContainer {
  margin-right: 1%;
}

ul {
  list-style-type: none;
}

body {
  margin: 0;
}
</style>
