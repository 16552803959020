<template>
  <Toast />
  <div class="container-fluid">
    <Menu />
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Accounting Transactions</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />

    <form @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="costType" class="text-bold">Firm</label>
          <v-select
            id="firm"
            :options="firmModel"
            label="firm_name"
            v-model="transactionModel.firm"
            :reduce="(manuf) => manuf.manufacturer_id"
          >
          </v-select>
        </div>

        <div class="col-md-2">
          <label for="expenseDate" class="text-bold">Date &nbsp;</label>
          <input
            type="date"
            id="expenseDate"
            v-model="transactionModel.date"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="invoiceNo" class="text-bold">Ref</label>
          <input
            type="text"
            id="invoiceNo"
            v-model="transactionModel.invoice_no"
            class="form-control"
          />
        </div>

        <!-- <div class="col-md-2">
                        <label for="store" class="text-bold">Store</label>
                        <input type="text" id="store" v-model="store_name" class="form-control" disabled>
                    </div> -->

        <!-- <div class="col-md-2">
                        <label for="user" class="text-bold">User</label>
                        <input type="text" id="user" v-model="user" class="form-control" disabled>
                    </div> -->
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="transactionType" class="text-bold"
            >Transaction Type</label
          >
          <select
            name="Transaction Type"
            id="transactionType"
            v-model="transactionModel.transaction_type"
            class="form-control"
            required
          >
            <option value="Output">Output</option>
            <option value="Input">Input</option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="transactionMethod" class="text-bold"
            >Transaction Method</label
          >
          <select
            name="Transaction Method"
            id="transactionMethod"
            v-model="transactionModel.transaction_method_new"
            class="form-control"
          >
            <option value=""></option>

            <option
              v-for="item in transactionMethodModel"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-2"></div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="paymentType" class="text-bold">Payment Type</label>
          <select
            name="Payment Type"
            id="paymentType"
            v-model="transactionModel.payment_type"
            class="form-control"
            required
          >
            <option value="ALTIN">ALTIN</option>
            <option value="CHF">CHF</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="TL">TL</option>
            <option value="USD">USD</option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="paymentMethod" class="text-bold">Payment Method</label>
          <select
            name="Payment Method"
            id="paymentMethod"
            v-model="transactionModel.payment_method"
            class="form-control"
          >
            <option value="Check">Check</option>
            <option value="Cash">Cash</option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="amount" class="text-bold">Amount</label>
          <input
            type="number"
            step="0.01"
            placeholder="0.00"
            id="amount"
            v-model="transactionModel.amount"
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <label for="description" class="text-bold">Description</label>
          <input
            type="text"
            id="description"
            v-model="transactionModel.description"
            class="form-control"
          />
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col-md-1">
          <input class="btn btn-secondary" type="submit" value="Submit" />

          <!-- <button @click="submit" id="" class="btn btn-secondary" type="submit" >Submit</button> -->
        </div>
      </div>
    </form>
    <!-- buras -->

    <hr class="mt-3" />
    <div class="row">
      <div class="col-md-2">
        <label for="firmFilter">Firm</label>
        <v-select
          multiple
          id="firmFilter"
          :options="firmModel"
          label="firm_name"
          v-model="apiFliters.firm"
          :reduce="(firm) => firm.manufacturer_id"
        >
        </v-select>
      </div>

      <div class="col-md-2">
        <label for="gte_date">Min Date</label>
        <input
          type="date"
          id="gte_date"
          v-model="apiFliters.gte__date"
          class="form-control"
        />
      </div>

      <div class="col-md-2">
        <label for="lte_date">Max Date</label>
        <input
          type="date"
          id="lte_date"
          v-model="apiFliters.lte__date"
          class="form-control"
        />
      </div>

      <div class="col-md-2">
        <label for="transactionType" class="text-bold">Transaction Type</label>
        <select
          name="Transaction Type"
          id="transactionType"
          v-model="apiFliters.transaction_type"
          class="form-control"
          required
        >
          <option value=""></option>
          <option value="Output">Output</option>
          <option value="Input">Input</option>
        </select>
      </div>

      <div class="col-md-2">
        <label for="transactionMethod" class="text-bold"
          >Transaction Method</label
        >
        <select
          name="Transaction Method"
          id="transactionMethod"
          v-model="apiFliters.transaction_method_new__id"
          class="form-control"
        >
          <option value=""></option>

          <option
            v-for="item in transactionMethodModel"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-md-2">
        <label for="paymentType" class="text-bold">Payment Type</label>
        <select
          name="Payment Type"
          id="paymentType"
          v-model="apiFliters.payment_type"
          class="form-control"
        >
          <option value=""></option>
          <option value="ALTIN">ALTIN</option>
          <option value="CHF">CHF</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="TL">TL</option>
          <option value="USD">USD</option>
        </select>
      </div>
      <div class="col-md-2">
        <label for="typeInput">Firm Type</label>
        <v-select
          id="firm"
          :options="typeModel"
          label="manufacturer_type"
          v-model="apiFliters.types"
          :reduce="(type) => type.manufacturer_type_id"
          multiple
        >
        </v-select>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-1">
        <button
          @click="saveApiFilters()"
          type="button"
          class="btn btn-secondary my-3"
        >
          Filter
        </button>
      </div>
    </div>
    <div class="row justify-content-center mt-2">
      <div class="col-md-6">
        <small
          >The data in the <b>Total</b> row changes only when the firm
          changes.</small
        >
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">TL</th>
              <th scope="col">USD</th>
              <th scope="col">EUR</th>
              <th scope="col">CHF</th>
              <th scope="col">GBP</th>
              <th scope="col">ALTIN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row justify-content-center">Subtotal</th>
              <td>{{ sumModel["TL"] || null }}</td>
              <td>{{ sumModel["USD"] || null }}</td>
              <td>{{ sumModel["EUR"] || null }}</td>
              <td>{{ sumModel["CHF"] || null }}</td>
              <td>{{ sumModel["GBP"] || null }}</td>
              <td>{{ sumModel["ALTIN"] || null }}</td>
            </tr>
            <tr>
              <th scope="row justify-content-center">Total</th>
              <td>{{ grandTotal["TL"] || null }}</td>
              <td>{{ grandTotal["USD"] || null }}</td>
              <td>{{ grandTotal["EUR"] || null }}</td>
              <td>{{ grandTotal["CHF"] || null }}</td>
              <td>{{ grandTotal["GBP"] || null }}</td>
              <td>{{ grandTotal["ALTIN"] || null }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row justify-content-center transactionTable mt-5">
      <div class="col-md-11">
        <Table
          v-if="showTable"
          :isAccounting="true"
          excelName="accounnting-transactions-export"
          excelTabName="Transactions"
          @deleteEvent="confirmDeleteTransaction"
          :tableInfo="[
            { header: 'Date', field: 'date', order: true },
            { header: 'Firm', field: 'firm_name' },
            {
              header: 'Transaction Type',
              field: 'transaction_type',
              order: true,
            },
            {
              header: 'Transaction Method',
              field: 'transaction_method_name',
            },
            { header: 'Payment Method', field: 'payment_method', order: true },

            { header: 'ALTIN', field: 'accounting_transaction' },
            { header: 'CHF', field: 'accounting_transaction' },
            { header: 'EUR', field: 'accounting_transaction' },
            { header: 'GBP', field: 'accounting_transaction' },
            { header: 'TL', field: 'accounting_transaction' },
            { header: 'USD', field: 'accounting_transaction' },
            { header: 'Balance', field: 'balance' },

            // {header:&quot;Amount&quot;,field:&quot;amount&quot;,order:true},
            { header: 'Ref', field: 'invoice_no', editable: true },
            { header: 'Description', field: 'description', order: true },

            { header: '-', field: 'delete', isDelete: true },
          ]"
          :endPoint="endPoint"
          :showRoute="false"
          :useCreate="true"
          :key="tableKey"
          :customColumn="true"
          @inputEvent="updateInvoiceNo"
        />
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="deleteTransactionDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteTransactionDialog = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deleteTransaction"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
import Table from "@/components/Table.vue";

export default {
  name: "Transactions",
  components: { Menu, Table },

  data: function () {
    return {
      tableKey: 0,
      endPoint: null,
      showTable: false,
      sumModel: {},
      grandTotal: {},
      transactionModel: {
        date: new Date().toLocaleDateString("fr-CA"),
        description: null,
        invoice_no: null,
        amount: null,
        transaction_type: null,
        transaction_method_new: null,
        payment_method: null,
        payment_type: null,
        firm: null,
      },
      store_name: localStorage.getItem("store"),
      user: localStorage.getItem("username"),
      firmModel: [],
      dateFilter: null,
      deleteTransactionDialog: false,
      transactionDelete: null,
      tableData: [],
      apiFliters: {
        firm: null,
        // gte__date:new Date().toISOString().split('T')[0],
        // lte__date:new Date().toISOString().split('T')[0],
        // lte__date:new Date().toISOString().split('T')[0],
        // gte__date:new Date(new Date().getFullYear(),new Date().getMonth(),2).toISOString().split('T')[0],
        lte__date: null,
        gte__date: null,
        transaction_method_new__id: null,
        payment_type: null,
        transaction_type: "",
        types: [],
      },
      transactionMethodModel: [],
      typeModel: [],
    };
  },

  methods: {
    submit() {
      let payload = this.transactionModel;
      payload["amount"] = payload["amount"] || null;
      if (payload.amount === null || payload.amount === "") {
        window.alert("Please enter an amount!");
        return true;
      }
      postAPI("accounting-transactions", payload).then((res) => {
        // getAPI('accounting-transactions?limit=10000&' + this.dateFilter).then((res) => {
        //     this.tableData = res.data.results;
        // })
        if (res) {
          Toast2.fire({
            icon: "success",
            title: "Transaction added!",
            text: "Please refresh the filter.",
          });
          this.transactionModel = {
            date: new Date().toLocaleDateString("fr-CA"),
            description: null,
            invoice_no: null,
            amount: null,
            transaction_type: null,
            transaction_method_new: null,
            payment_method: null,
            payment_type: null,
            firm: null,
          };
        }
      });
    },
    finder(data, type) {
      if (data.payment_type === type) {
        return data.amount;
      } else {
        return "";
      }
    },

    tableTotal(currency, type) {
      let total = 0;
      for (let trans of this.tableData) {
        if (trans.payment_type === currency) {
          if (trans.transaction_type === type) {
            total += trans.amount;
          }
        }
      }

      return total;
    },
    bigTotal(currency) {
      let totalI = 0;
      let totalO = 0;
      for (let trans of this.tableData) {
        if (trans.payment_type === currency) {
          if (trans.transaction_type === "Input") {
            totalI += trans.amount;
          }
          if (trans.transaction_type === "Output") {
            totalO += trans.amount;
          }
        }
      }
      return totalI - totalO;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    openFilter() {
      // jQuery.noConflict();
      // $('#filters').modal('show');
      jQuery("#filters").modal("show");
    },

    saveApiFilters() {
      let filters = this.apiFliters;
      let queryList = [
        "firm__manufacturer_id__in=" + filters.firm,
        "date__lte=" + filters.lte__date,
        "date__gte=" + filters.gte__date,
        "transaction_method_new__id=" + filters.transaction_method_new__id,
        "payment_type=" + filters.payment_type,
        "transaction_type=" + filters.transaction_type,
        "firm__type_id__in=" + filters.types,
      ];
      let query = queryList.join("&").replaceAll("null", "");
      this.endPoint = "accounting-transactions?&ordering=-id&" + query;

      getAPI("sum-accounting-transactions?limit=1&" + query).then((res) => {
        this.sumModel = res.data;
      });

      var grandQuery = "firm__manufacturer_id__in=" + filters.firm;
      grandQuery = grandQuery.replaceAll("null", "");
      getAPI("sum-accounting-transactions?limit=1&" + grandQuery).then(
        (res) => {
          this.grandTotal = res.data;
        }
      );

      this.showTable = true;
      this.tableKey += 1;
    },
    confirmDeleteTransaction(data) {
      this.deleteTransactionDialog = true;
      this.transactionDelete = data;
    },
    deleteTransaction() {
      deleteAPI("accounting-transactions/" + this.transactionDelete["id"]).then(
        (res) => {
          this.deleteTransactionDialog = false;
          this.tableKey += 1;
          // getAPI('accounting-transactions?limit=10000&' + this.dateFilter).then((res) => {
          //     this.tableData = res.data.results;
          //     this.deleteTransactionDialog = false

          // })
        }
      );
    },

    updateInvoiceNo(row) {
      const payload = {
        invoice_no: row.invoice_no,
      };
      try {
        patchAPI("accounting-transactions/" + row.id, payload).then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Custom Profit updated",
            life: 1000,
          });
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Custom Profit not updated",
          life: 1000,
        });
      }
    },
  },
  created() {
    getAPI("manufacturers?limit=10000").then((res) => {
      this.firmModel = res.data.results;
    });
    getAPI("accounting-transaction-methods?limit=10000").then((res) => {
      this.transactionMethodModel = res.data.results;
    });
    getAPI("manufacturer-types?limit=10000").then(
      (res) => (this.typeModel = res.data.results)
    );
    let yourDate = new Date();
    yourDate = yourDate.toISOString().split("T")[0];
    let dateFilter = "date__lte=" + yourDate + "&date__gte=" + yourDate + "&";
    this.dateFilter = dateFilter;
    this.saveApiFilters();
    // getAPI('sum-accounting-transactions?limit=1&')
    // .then((res) => {
    //     this.sumModel = res.data
    // })
  },
};
</script>

<style scoped></style>
