<template>
  <Menu />
  <Toast />
  <div class="container-fluid" v-if="page_permissions.show_end_of_day">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>End Of Day Report</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />
    <div class="row">
      <div class="col-md-2">
        <button @click="getTodaySales" class="btn btn-secondary">
          Today Sales
        </button>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label for="costType" class="text-bold">Cost Type</label>
              <v-select
                id="costType"
                :options="costTypeModel"
                label="name"
                v-model="endOfDayModel.cost_type"
                :reduce="(cost_type) => cost_type.id"
              >
              </v-select>
            </div>

            <div class="col-md-4">
              <label for="expenseDate" class="text-bold">Date &nbsp;</label>
              <input
                type="date"
                id="expenseDate"
                v-model="endOfDayModel.date"
                class="form-control"
              />
            </div>

            <div class="col-md-3">
              <label for="store" class="text-bold">Store</label>
              <input
                type="text"
                id="store"
                v-model="store_name"
                class="form-control"
                disabled
              />
            </div>

            <!-- <div class="col-md-2">
            <label for="user" class="text-bold">User</label>
            <input type="text" id="user" v-model="user" class="form-control" disabled>
        </div> -->
          </div>

          <div class="row justify-content-center">
            <div class="col-md-12">
              <label for="description" class="text-bold">Description</label>
              <textarea
                type="text"
                id="description"
                v-model="endOfDayModel.description"
                class="form-control"
              ></textarea>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-7">
              <div class="form-group row">
                <label for="tlCost" class="text-bold col-md-4">TL Amount</label>
                <div class="col-md-7">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="tlCost"
                    v-model="endOfDayModel.tl_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >USD Amount</label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="usdCost"
                    v-model="endOfDayModel.usd_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >EUR Amount</label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="eurCost"
                    v-model="endOfDayModel.eur_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >CHF Amount</label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="chfCost"
                    v-model="endOfDayModel.chf_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >GBP Amount</label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="gbpCost"
                    v-model="endOfDayModel.gbp_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >GOLD Amount</label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="goldCost"
                    v-model="endOfDayModel.gold_cost"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mt-5 mb-3">
            <div class="col-md-1">
              <input class="btn btn-secondary" type="submit" value="Submit" />

              <!-- <button @click="submit" class="btn btn-secondary" type="submit" >Submit</button> -->
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- burası -->
    <hr class="mt-5" />
    <div class="row justify-content-center endTable" style="margin-top: 20px">
      <div class="col-md-12">
        <div class="row justify-content-center">
          <div class="col-md-2">
            <label for="range" class="text-bold">Start Date</label>
            <input
              type="date"
              v-model="apiFilters.gte__date"
              id="gte_date"
              class="form-control"
            />
          </div>

          <div class="col-md-2">
            <label for="range" class="text-bold">End Date</label>
            <input
              type="date"
              v-model="apiFilters.lte__date"
              id="lte_date"
              class="form-control"
            />
          </div>

          <div class="col-md-2">
            <label for="costType" class="text-bold">Cost Type</label>
            <v-select
              multiple
              class="p-datatable-sm"
              id="costType"
              :options="costTypeModel"
              label="name"
              v-model="apiFilters.cost_type"
              :reduce="(cost_type) => cost_type.id"
            />
          </div>
          <div class="col-md-2">
            <label for="costGroup" class="text-bold">Cost Group</label>
            <v-select
              id="costGroup"
              v-model="apiFilters.cost_group"
              :options="costGroupData"
              label="name"
              :reduce="(costGroup) => costGroup.id"
              multiple
            />
          </div>
          <div class="col-md-2">
            <button
              type="button"
              @click="filterSubmit"
              id="submitFilter"
              class="btn btn-outline-dark my-3"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <Table
      v-if="showTable"
      excelName="cost-export"
      excelTabName="Costs"
      @deleteEvent="confirmDeleteCost"
      :tableInfo="[
        { header: 'Created At', field: 'created_at_date', order: false },

        { header: 'Date', field: 'date', order: true },
        { header: 'Cost', field: 'cost_name' },
        { header: 'Group', field: 'cost_group_name' },

        { header: 'User', field: 'user_name' },
        { header: 'Description', field: 'description' },
        { header: 'TL', field: 'tl_cost', order: true },
        { header: 'USD', field: 'usd_cost', order: true },
        { header: 'EUR', field: 'eur_cost', order: true },
        { header: 'CHF', field: 'chf_cost', order: true },
        { header: 'GBP', field: 'gbp_cost', order: true },
        { header: 'Gold', field: 'gold_cost', order: true },
        { header: '-', field: 'delete', isDelete: true },
      ]"
      :endPoint="endPoint"
      :showRoute="false"
      :useCreate="true"
      :key="tableKey"
    />
    <div class="row justify-content-center mt-5">
      <div class="col-md-6">
        <!-- <small>At first it shows the whole total, when the filter changes, the total changes according to filters.</small> -->
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">TL</th>
              <th scope="col">USD</th>
              <th scope="col">EUR</th>
              <th scope="col">CHF</th>
              <th scope="col">GBP</th>
              <th scope="col">Gold</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row justify-content-center">Subtotal</th>
              <td>{{ subSumModel["total_tl_cost"] || null }}</td>
              <td>{{ subSumModel["total_usd_cost"] || null }}</td>
              <td>{{ subSumModel["total_eur_cost"] || null }}</td>
              <td>{{ subSumModel["total_chf_cost"] || null }}</td>
              <td>{{ subSumModel["total_gbp_cost"] || null }}</td>
              <td>{{ subSumModel["total_gold_cost"] || null }}</td>
            </tr>
            <tr>
              <th scope="row justify-content-center">Total</th>
              <td>{{ sumModel["total_tl_cost"] || null }}</td>
              <td>{{ sumModel["total_usd_cost"] || null }}</td>
              <td>{{ sumModel["total_eur_cost"] || null }}</td>
              <td>{{ sumModel["total_chf_cost"] || null }}</td>
              <td>{{ sumModel["total_gbp_cost"] || null }}</td>
              <td>{{ sumModel["total_gold_cost"] || null }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Dialog
      v-model:visible="deleteCostDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <span>Are you sure you want to delete?</span>
      </div>
      <template #footer>
        <div class="row justify-content-center">
          <div class="offset-md-8 col-md-2">
            <button
              @click="deleteCostDialog = false"
              type="button"
              class="btn btn-outline-dark d-flex justify-content-center p-button-text"
            >
              No
            </button>
          </div>
          <div class="col-md-2">
            <button
              @click="deleteCost"
              type="button"
              class="btn btn-outline-danger d-flex justify-content-center"
            >
              Yes
            </button>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Table from "@/components/Table.vue";

export default {
  components: { Menu, Table },
  data: function () {
    return {
      page_permissions:
        JSON.parse(localStorage.getItem("page_permissions")) || [],
      tableKey: 0,
      endPoint: null,
      showTable: false,
      minPickerDate: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toLocaleDateString("fr-CA"),
      maxPickerDate: new Date().toLocaleDateString("fr-CA"),

      sumModel: {},
      storeFilter: "store__id=" + this.$store.state.store_id,
      apiFilters: {
        store: null,
        lte__date: new Date().toISOString().split("T")[0],
        // gte__date:new Date(new Date().getFullYear(),new Date().getMonth(),2).toISOString().split('T')[0],
        gte__date: new Date().toISOString().split("T")[0],

        cost_type: null,
        cost_group: null,
      },
      endOfDayModel: {
        date: new Date().toLocaleDateString("fr-CA"),
        description: null,
        tl_cost: null,
        usd_cost: null,
        eur_cost: null,
        chf_cost: null,
        gbp_cost: null,
        gold_cost: null,
        store: null,
        user: null,
        cost_type: null,
      },
      costTypeModel: [],
      costGroupData: [],
      store_name: localStorage.getItem("store"),
      user: localStorage.getItem("username"),
      tableData: null,
      dateFilter: null,
      userModel: null,
      columns: [
        // {field:"date", header:"Date"},
        // {field:"cost_type", header:"Type"},
        // {field:"user", header:"User"},
        // {field:"description", header:"Description"},
        { field: "tl", header: "TL" },
        { field: "usd", header: "USD" },
        { field: "eur", header: "EUR" },
        { field: "chf", header: "CHF" },
        { field: "gbp", header: "GBP" },
        { field: "gold", header: "Gold" },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      dateRangeFilter: [new Date(), new Date()],
      loading: false,
      deleteCostDialog: false,
      costDelete: null,
      total: {
        tl: null,
        usd: null,
        eur: null,
        chf: null,
        gbp: null,
        gold: null,
      },
      subSumModel: [],
    };
  },
  end: {
    date: new Date().toLocaleDateString("fr-CA"),
    description: null,
    tl_cost: null,
    usd_cost: null,
    eur_cost: null,
    chf_cost: null,
    gbp_cost: null,
    gold_cost: null,
    store: null,
    user: null,
    cost_type: null,
  },
  created: function () {
    this.end = this.endOfDayModel;
    let yourDate = new Date();
    yourDate = yourDate.toISOString().split("T")[0];
    let dateFilter = "date__lte=" + yourDate + "&date__gte=" + yourDate + "&";
    this.dateFilter = dateFilter;
    getAPI("cost-types?limit=10000&ordering=name").then((res) => {
      this.costTypeModel = res.data.results;
      this.endOfDayModel["cost_type"] = this.costTypeModel[0]["id"];
    });
    getAPI("cost-groups?limit=10000&ordering=name").then((res) => {
      this.costGroupData = res.data.results;
    });

    // getAPI('user-list?limit=10000').then((res) => {
    //     this.userModel = res.data.results
    // })
    this.filterSubmit();
    // getAPI('sum-end-of-day?limit=20&' + this.storeFilter).then((res) => {
    //     this.sumModel = res.data
    // })
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    submit() {
      const end = {
        date: new Date().toLocaleDateString("fr-CA"),
        description: null,
        tl_cost: null,
        usd_cost: null,
        eur_cost: null,
        chf_cost: null,
        gbp_cost: null,
        gold_cost: null,
        store: null,
        user: null,
        cost_type: null,
      };
      let payload = this.endOfDayModel;
      payload["tl_cost"] = payload["tl_cost"] || null;
      payload["gold_cost"] = payload["gold_cost"] || null;
      payload["gbp_cost"] = payload["gbp_cost"] || null;
      payload["eur_cost"] = payload["eur_cost"] || null;
      payload["usd_cost"] = payload["usd_cost"] || null;
      payload["chf_cost"] = payload["chf_cost"] || null;

      postAPI("end-of-day", payload).then((res) => {
        // getAPI('end-of-day?limit=100000&' + this.dateFilter).then((res) => {
        //     this.tableData = res.data.results;
        //     this.totalCalculate();
        // })
        this.filterSubmit();
        this.endOfDayModel = end;
        this.endOfDayModel["cost_type"] = this.costTypeModel[0]["id"];

        // getAPI('sum-end-of-day?limit=20&' + this.storeFilter).then((res) => {
        //     this.sumModel = res.data
        // })
      });
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    dateChange() {
      this.loading = true;
      let date1 = new Date(
        this.dateRangeFilter[0].getTime() -
          this.dateRangeFilter[0].getTimezoneOffset() * 60000
      );
      // date1.setDate(date1.getDate() + 1);
      console.log(this.dateRangeFilter);
      let startDate = date1.toISOString().slice(0, 10).replace("T", " ");
      if (this.dateRangeFilter[1]) {
        let date2 = new Date(
          this.dateRangeFilter[1].getTime() -
            this.dateRangeFilter[1].getTimezoneOffset() * 60000
        );
        let endDate = date2.toISOString().slice(0, 10).replace("T", " ");
        let query = `end-of-day?ordering=-date,-id&limit=100000&date__gte=${startDate}&date__lte=${endDate}`;
        console.log(query);
        getAPI(query).then((res) => {
          this.tableData = res.data.results;
          this.loading = false;
          this.totalCalculate();
        });
      }
    },

    totalCalculate() {
      const value = this.tableData;
      var tl = 0;
      var usd = 0;
      var eur = 0;
      var chf = 0;
      var gbp = 0;
      var gold = 0;

      // this.tableData.forEach( item => {
      //     this.total.tl += item.tl_cost
      //     this.total.usd += item.usd_cost
      //     this.total.eur += item.eur_cost
      //     this.total.chf += item.chf_cost
      //     this.total.gbp += item.gbp_cost
      //     this.total.gold += item.gold_cost

      // })

      for (const item of value) {
        tl += item.tl_cost;
        usd += item.usd_cost;
        eur += item.eur_cost;
        chf += item.chf_cost;
        gbp += item.gbp_cost;
        gold += item.gold_cost;
      }
      this.total.tl = tl;
      this.total.usd = usd;
      this.total.eur = eur;
      this.total.chf = chf;
      this.total.gbp = gbp;
      this.total.gold = gold;
    },
    confirmDeleteCost(data) {
      this.deleteCostDialog = true;
      this.costDelete = data;
    },
    deleteCost() {
      deleteAPI("end-of-day/" + this.costDelete["id"]).then((res) => {
        this.deleteCostDialog = false;
        this.tableKey += 1;
        // getAPI('end-of-day?limit=100000&' + this.dateFilter).then((res) => {
        //     this.tableData = res.data.results;
        //     this.totalCalculate();
        //     this.deleteCostDialog = false

        // })
      });
    },
    filterSubmit() {
      let filters = this.apiFilters;
      let queryList = [
        "date__lte=" + filters.lte__date,
        "date__gte=" + filters.gte__date,
        "cost_type__id__in=" + filters.cost_type,
        "cost_type__group__id__in=" + filters.cost_group,
      ];
      let query = queryList.join("&").replaceAll("null", "");
      this.endPoint =
        "end-of-day?ordering=-date,-id&" + this.storeFilter + "&" + query;
      console.log(this.endPoint);
      getAPI("sum-end-of-day?limit=20&" + this.storeFilter).then((res) => {
        this.sumModel = res.data;
      });
      getAPI("sum-end-of-day?limit=20&" + this.storeFilter + "&" + query).then(
        (res) => {
          this.subSumModel = res.data;
          console.log(this.subSumModel);
        }
      );
      this.tableKey += 1;
      this.showTable = true;
    },

    getTodaySales() {
      let date = new Date();
      let dateString = date.toISOString().slice(0, 10);
      const saleStatus =
        "&sale_status__in=consignee,awaiting,completed,canceled";
      const store_id = localStorage.getItem("store_id");
      let tableData = [];
      const startDate = "2022-01-01";
      let endpoint = `sale-list?sale_date__lte=${dateString}&sale_date__gte=${dateString}&store_id__id=${store_id}&offset=0&limit=1000`;
      getAPI(endpoint).then((res) => {
        tableData = res.data.results;

        let columns = [
          "sale_date",
          "customer_name",
          "sale_type",
          "seller",
          "store_name",
          "total_expense",
          "total_sale_price",
        ];
        let tableHTML = `
            
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css">
        <table class="table table-hover table-bordered ">
        <thead>
          <tr>
            <th>Sale Reference</th>
            <th>Sale Date</th>
            <th>Customer Name</th>
            <th>Seller</th>
            <th>Sale Type</th>
            <th>Store</th>
            <th>Total Expense</th>
            <th>Total Sale</th>
          </tr>
        </thead>
        <tbody>
      `;
        tableData.forEach((item) => {
          tableHTML += `
          <tr>
            <td><a href="/sales/${item.sale_id}" target="_blank">${item.sale_reference}</a></td>
            <td>${item.sale_date}</td>
            <td>${item.customer_name}</td>
            <td>${item.seller}</td>
            <td>${item.sale_type}</td>
            <td>${item.store_name}</td>
            <td>${item.total_expense}</td>
            <td>${item.total_sale_price}</td>

          </tr>
        `;
        });
        tableHTML += `</tbody></table>`;

        // Open the new window
        let tableWindow = window.open(
          "",
          "Today Sales",
          "height=500,width=800"
        );
        tableWindow.document.write(tableHTML);
      });
    },
  },
};
</script>

<style scoped>
.endTable td {
  font-size: smaller;
}

input {
  height: 30px;
}
</style>
