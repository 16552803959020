<template>
  <Toast />
  <div class="container-fluid">
    <Menu />
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Exchange Rates</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />
    <div class="form-container container">
      <form @submit.prevent="saveExchangeRate">
        <div class="row">
          <div class="col-md-2">
            <label for="currency">Currency From</label>
            <select
              id="currency"
              v-model="rateModel.rate_from"
              class="form-control"
            >
              <option value="EUR">EUR</option>
              <option value="TRY">TRY</option>
              <option value="AED">AED</option>
            </select>
          </div>
          <div class="col-md-2">
            <label for="currency">Currency To</label>
            <select
              id="currency"
              v-model="rateModel.rate_to"
              class="form-control"
            >
              <option value="USD">USD</option>
            </select>
          </div>
          <div class="col-md-2">
            <label for="exchangeRate">Exchange Rate</label>
            <input
              id="exchangeRate"
              v-model="rateModel.exchange_rate"
              class="form-control"
              type="number"
              step="0.01"
            />
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary mt-4">Save</button>
          </div>
        </div>
      </form>
      <hr style="height: 0.5px; margin-bottom: 30px" />
      <div class="table-container container">
        <Table
          excelName="exchange-rates"
          excelTabName="Rates"
          endPoint="exchange-rates?"
          :key="tableKey"
          :tableInfo="tableInfo"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "@/components/Menu.vue";
import { postAPI } from "@/global/callAPI";
import Table from "@/components/Table.vue";
export default {
  components: {
    Menu,
    Table,
  },
  data() {
    return {
      rateModel: {
        rate_from: "EUR",
        rate_to: "USD",
        exchange_rate: 1,
        is_last: true,
      },
      tableKey: 0,
      tableInfo: [
        { header: "Date", field: "formatted_date" },
        { header: "Currency From", field: "rate_from" },
        { header: "Currency To", field: "rate_to" },
        { header: "Exchange Rate", field: "exchange_rate" },
        { header: "Is Last", field: "is_last" },
      ],
    };
  },
  methods: {
    saveExchangeRate() {
      console.log(this.rateModel);
      postAPI("exchange-rates", this.rateModel).then((res) => {
        console.log(res);
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Exchange rate created successfully!",
          life: 1000,
        });
        this.tableKey += 1;
        this.rateModel = {
          rate_from: "EUR",
          rate_to: "USD",
          exchange_rate: 1,
          is_last: true,
        };
      });
    },
  },
};
</script>
