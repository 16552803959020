import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Menu from "@/components/Menu.vue";

import NewSales from "@/views/Sale/NewSales.vue";
import SaleItem from "@/views/Sale/SaleItem.vue";
import PaymentTable from "@/views/Sale/PaymentTable.vue";
import ExchangeRate from "@/views/Sale/ExchangeRate.vue";

import NewJewel from "@/views/Product/NewJewel.vue";
import NewWatch from "@/views/Product/NewWatch.vue";
import JewelItem from "@/views/Product/JewelItem.vue";
import WatchItem from "@/views/Product/WatchItem.vue";
import ProductItem from "@/views/Product/ProductItem.vue";
// import ProductsTable from '@/views/Product/ProductsTable.vue'
import ProductExport from "@/views/Product/ProductExport.vue";

import TopMenu from "@/components/TopMenu.vue";
import ProductPhoto from "@/views/Product/ProductPhoto.vue";
import ProductSale from "@/views/Product/ProductSale.vue";
import ProductCatalog from "@/views/Product/ProductCatalogTable.vue";
import ProductTransferTable from "@/views/Product/ProductTransferTable.vue";
import DestroyProduct from "@/views/Product/DestroyProduct.vue";
import WatchDetailList from "@/views/Product/WatchDetailList.vue";
import BulkTagAdd from "@/views/Product/BulkTagAdd.vue";

import TreeStone from "@/components/TreeStone.vue";
import NewSalesTable from "@/views/Sale/NewSalesTable.vue";
import NewSaleProductsTable from "@/views/Sale/NewSaleProductsTable.vue";
import ReturnSaleTable from "@/views/Sale/ReturnSaleTable.vue";
import StoneView from "@/views/Gem/StoneView.vue";
import KaratPriceView from "@/views/Gem/KaratPrice.vue";
import NewTransfer from "@/views/Transfer/NewTransfer.vue";
import ProductSelect from "@/components/ProductSelect.vue";
import ProductSelectDeneme from "@/components/ProductSelectDeneme.vue";

import TransferItem from "@/views/Transfer/TransferItem.vue";
import NewCustomer from "@/views/Customer/NewCustomer.vue";
import CustomerList from "@/views/Customer/CustomerList.vue";
import CustomerItem from "@/views/Customer/CustomerItem.vue";
import NewManufacturer from "@/views/Manufacturer/NewManufacturer.vue";
import ManufacturerList from "@/views/Manufacturer/ManufacturerList.vue";
import ManufacturerItem from "@/views/Manufacturer/ManufacturerItem.vue";
import Catalog from "@/views/Catalog/Catalog.vue";
import CatalogGallery from "@/views/Catalog/CatalogGallery.vue";
import TransferList from "@/views/Transfer/TransferList.vue";
import OrderList from "@/views/Transfer/OrderList.vue";
import CatalogGalleryPrint from "@/views/Catalog/CatalogGalleryPrint.vue";
import AddDeleteCatalog from "@/views/Catalog/AddDeleteCatalog.vue";

import EndOfDay from "@/views/Accounting/EndOfDay.vue";
import Transactions from "@/views/Accounting/Transactions.vue";
import TransactionsSummary from "@/views/Accounting/TransactionsSummary.vue";
import AdminEndOfDay from "@/views/Accounting/AdminEndOfDay.vue";

import Register from "@/views/Settings/Register.vue";
import ChangePassword from "@/views/Settings/ChangePassword.vue";

import StonePropertyAdd from "@/views/Settings/Add/StonePropertyAdd.vue";
import AddJewelModel from "@/views/Settings/Add/JewelModelAdd.vue";
import AddJewelType from "@/views/Settings/Add/JewelTypeAdd.vue";

import ExpenseTypeAdd from "@/views/Settings/Add/ExpenseTypeAdd.vue";
import CostTypeAdd from "@/views/Settings/Add/CostTypeAdd.vue";
import CostGroupAdd from "@/views/Settings/Add/CostGroupAdd.vue";

import ManufTypeAdd from "@/views/Settings/Add/ManufTypeAdd.vue";
import StoreAdd from "@/views/Settings/Add/StoreAdd.vue";
import WatchBrandAdd from "@/views/Settings/Add/WatchBrandAdd.vue";
import WatchPropertyAdd from "@/views/Settings/Add/WatchPropertyAdd.vue";
import JewelProductTypeAdd from "@/views/Settings/Add/JewelProductTypeAdd.vue";
import CatalogAdd from "@/views/Settings/Add/CatalogAddView.vue";
import CustomerTagAddView from "@/views/Settings/Add/CustomerTagAddView.vue";
import ProductTagAddView from "@/views/Settings/Add/ProductTagAddView.vue";
import GemTagAddView from "@/views/Settings/Add/GemTagAddView.vue";
import SaleTagAddView from "@/views/Settings/Add/SaleTagView.vue";
import UploadProductPhoto from "@/views/Settings/UploadProductPhoto.vue";
import ConnectDatabase from "@/views/Settings/ConnectDatabase.vue";
import WatchConditionAdd from "@/views/Settings/Add/WatchConditionAdd.vue";
import TransactionMethodAdd from "@/views/Settings/Add/TransactionMethodAdd.vue";

import Export from "@/views/Settings/Export.vue";

import ProductSearch from "@/views/Product/ProductSearchView.vue";

import PageNotFound from "@/views/PageNotFound/PageNotFound.vue";

import Dashboard from "@/views/Dashboard.vue";

import MerchantProducts from "@/views/Merchant/MerchantProducts.vue";
import BulkAddDeleteMerchantProduct from "@/views/Merchant/BulkAddDeleteMerchantProduct.vue";

import NewLoan from "@/views/Loan/NewLoan.vue";
import LoanList from "@/views/Loan/LoanList.vue";
import UpdateLoan from "@/views/Loan/UpdateLoan.vue";
function hasPermission(roles) {
  let user_groups = store.state.groups;
  if (Array.isArray(user_groups)) {
  } else {
    user_groups = user_groups.split(",");
  }
  if (user_groups.some((r) => roles.includes(r))) {
    return false;
  } else {
    return true;
  }
}

const page_permissions = JSON.parse(store.state.page_permissions);

const routes = [
  {
    path: "/merchant-products",
    name: "MerchantProducts",
    component: MerchantProducts,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    alias: "/login",
  },
  { path: "/home", name: "home", component: Home },
  {
    path: "/new-sales",
    name: "NewSales",
    component: NewSales,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/sales/:id",
    name: "SaleItem",
    component: SaleItem,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/sales",
    name: "SaleShow",
    component: NewSalesTable,
    meta: {
      requiresLogin: false,
    },
  },

  {
    path: "/return-sales",
    name: "ReturnSaleTable",
    component: ReturnSaleTable,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/exchange-rates",
    name: "ExchangeRate",
    component: ExchangeRate,
    meta: {
      requiresLogin: false,
    },
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller", "office"])) {
        next();
      } else {
        next("/home");
      }
    },
  },
  // {
  //     path: '/sales-table',
  //     name: 'NewSalesTable',
  //     component: NewSalesTable
  // },
  // {
  //     path: '/sales-products-table',
  //     name: 'NewSaleProductsTable',
  //     component: NewSaleProductsTable
  // },
  {
    path: "/sale-products",
    name: "SaleProducts",
    component: NewSaleProductsTable,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/payments",
    name: "PaymentTable",
    component: PaymentTable,
    meta: {
      requiresLogin: false,
    },
  },

  {
    path: "/new-jewel",
    name: "NewJewel",
    component: NewJewel,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/new-watch",
    name: "NewWatch",
    component: NewWatch,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/merchant-settings",
    name: "AddDeleteMerchantProduct",
    component: BulkAddDeleteMerchantProduct,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/products/:id",
    name: "ProductItem",
    component: ProductItem,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/watches/:id",
    name: "WatchItem",
    component: WatchItem,
    meta: {
      requiresLogin: false,
    },
  },
  // {
  //     path: '/product-list',
  //     name: 'ProductsTable',
  //     component: ProductsTable,
  //     meta: {
  //         requiresLogin: false
  //     }
  // },
  {
    path: "/product-search",
    name: "ProductSearcb",
    component: ProductSearch,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/watch-detail-list",
    name: "WatchDetailList",
    component: WatchDetailList,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/product-export",
    name: "ProductExport",
    component: ProductExport,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/destroy-product",
    name: "DestroyProduct",
    component: DestroyProduct,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/bulk-product-tag",
    name: "BulkProductTag",
    component: BulkTagAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },

  {
    path: "/menu",
    name: "TopMenu",
    component: TopMenu,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/home",
    name: "MenuPage",
    component: Menu,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/products/photo/:id",
    name: "ProductPhoto",
    component: ProductPhoto,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/products/sales/:id",
    name: "ProductSale",
    component: ProductSale,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/products/catalog/:id",
    name: "ProductCatalog",
    component: ProductCatalog,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/products/transfer/:id",
    name: "ProductTransfer",
    component: ProductTransferTable,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/tree",
    name: "TreeStone",
    component: TreeStone,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/gems",
    name: "StoneView",
    component: StoneView,
    beforeEnter(to, from, next) {
      if (hasPermission(["limited_seller", "seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/karat-price",
    name: "KaratPriceView",
    component: KaratPriceView,
    beforeEnter(to, from, next) {
      if (hasPermission(["limited_seller", "seller", "office"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/new-transfer",
    name: "TransferView",
    component: NewTransfer,
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: "/product-select",
    name: "ProductSelect",
    component: ProductSelect,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/transfers/:id",
    name: "TransferItem",
    component: TransferItem,
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: "/new-loan",
    name: "NewLoan",
    component: NewLoan,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/loans",
    name: "LoanList",
    component: LoanList,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/loans/:id",
    name: "UpdateLoan",
    component: UpdateLoan,
    meta: {
      requiresLogin: false,
    },
  },

  {
    path: "/new-customer",
    name: "NewCustomer",
    component: NewCustomer,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/customers",
    name: "CustomerList",
    component: CustomerList,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/customers/:id",
    name: "CustomerItem",
    component: CustomerItem,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/new-manufacturer",
    name: "NewManufacturer",
    component: NewManufacturer,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/manufacturers",
    name: "ManufacturerList",
    component: ManufacturerList,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/manufacturers/:id",
    name: "ManufacturerItem",
    component: ManufacturerItem,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/catalog",
    name: "CatalogShow",
    component: Catalog,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-delete-catalog",
    name: "AddDeleteCatalog",
    component: AddDeleteCatalog,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/catalog-gallery",
    name: "CatalogGallery",
    component: CatalogGallery,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/catalog/print/:id",
    name: "CatalogGalleryPrint",
    component: CatalogGalleryPrint,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/transfers",
    name: "TransferList",
    component: TransferList,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/orders",
    name: "OrderList",
    component: OrderList,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/accounting-transactions",
    name: "AccountingTransactions",
    component: Transactions,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/accounting-transactions-summary",
    name: "AccountingTransactionsSummary",
    component: TransactionsSummary,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/end-of-day",
    name: "EndOfDay",
    component: EndOfDay,
    beforeEnter(to, from, next) {
      if (page_permissions.show_end_of_day) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/end-of-day-admin",
    name: "EndOfDayAdmin",
    component: AdminEndOfDay,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/new-user",
    name: "Register",
    component: Register,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller", "office"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-stone-property",
    name: "StonePropertyAdd",
    component: StonePropertyAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-jewel-type",
    name: "AddJewelType",
    component: AddJewelType,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-jewel-model",
    name: "AddJewelModel",
    component: AddJewelModel,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-expense-type",
    name: "AddExpenseType",
    component: ExpenseTypeAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-cost-type",
    name: "AddCostType",
    component: CostTypeAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-cost-group",
    name: "AddCostGroup",
    component: CostGroupAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller", "office"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },

  {
    path: "/add-manufacturer-type",
    name: "ManufTypeAdd",
    component: ManufTypeAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-store",
    name: "StoreAdd",
    component: StoreAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-watch-brand",
    name: "WatchBrandAdd",
    component: WatchBrandAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-watch-condition",
    name: "WatchConditionAdd",
    component: WatchConditionAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-transaction-method",
    name: "TransactionMethodAdd",
    component: TransactionMethodAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-watch-property",
    name: "WatchPropertyAdd",
    component: WatchPropertyAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-jewel-product-type",
    name: "JewelProductTypeAdd",
    component: JewelProductTypeAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-catalog",
    name: "CatalogAddView",
    component: CatalogAdd,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-customer-tag",
    name: "CustomerTagAdd",
    component: CustomerTagAddView,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-product-tag",
    name: "ProducTagAddd",
    component: ProductTagAddView,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-sale-tag",
    name: "SaleTagAdd",
    component: SaleTagAddView,
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/export-data",
    name: "ExportData",
    component: Export,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },

  {
    path: "/connect-database",
    name: "ConnectDatabase",
    component: ConnectDatabase,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller", "office"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/add-gem-tag",
    name: "GemTagAdd",
    component: GemTagAddView,
    beforeEnter(to, from, next) {
      if (hasPermission(["limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/upload-product-photo",
    name: "UploadProductPhoto",
    component: UploadProductPhoto,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (hasPermission(["seller", "limited_seller", "office"])) {
        next();
      } else {
        next("/home");
      }
    },
    meta: {
      requiresLogin: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// const router = new VueRouter({ routes, mode: 'history' });

export default router;
