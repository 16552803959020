<template>
  <Menu />
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Sales List
    </h6>
    <hr />
    <form @submit.prevent="applyFilter()">
      <div class="row justify-content-center">
        <div class="d-flex flex-fill mb-3">
          <button
            v-for="cFilter in customDateFilter"
            @click="applyCustomDateFilter(cFilter.start_date, cFilter.end_date)"
            :key="cFilter.header"
            type="button"
            class="btn btn-secondary btn-sm mt-2 custom-date-filter-button text-wrap"
          >
            {{ cFilter.label }}
          </button>
        </div>
        <div class="col-md-2 offset-md-1">
          <label type="date" for="nameInput">Start Date</label>
          <input
            class="form-control"
            type="date"
            name="Customer Name"
            id="nameInput"
            v-model="filterModel.start_date"
          />
        </div>

        <div class="col-md-2">
          <label for="nameInput">End Date</label>
          <input
            class="form-control"
            type="date"
            name="Customer Name"
            id="nameInput"
            v-model="filterModel.end_date"
          />
        </div>
        <div class="col-md-2">
          <label for="saleStatus">Sale Status</label>
          <!-- <select v-model="filterModel.status" id="saleStatus" class="form-select" >
                    <option value="sale__is_last=true&sale__is_return=false&is_canceled=false" ></option>

                    <option value="is_paid=true&sale__is_last=true&sale__is_return=false&is_canceled=false">Completed</option>
                    <option value="sale_type_id__sale_type_id__in=2,6&is_paid=false&sale__is_last=true&sale__is_return=false&is_canceled=false">Awaiting</option>
                    <option value="sale_type_id__sale_type_id=8&sale__is_last=true&sale__is_return=false&is_canceled=false">Consignee</option>
                    <option value="sale__is_last=true&sale__is_return=false&is_canceled=true">Canceled</option>
                </select> -->
          <!-- <select v-model="filterModel.sale_status" id="saleStatus" class="form-select">
                        <option value="consignee,awaiting,completed,canceled">All</option>
                        <option value="completed">Completed</option>
                        <option value="awaiting">Awaiting</option>
                        <option value="consignee">Consignee</option>
                        <option value="canceled">Canceled</option>
                    </select> -->

          <v-select
            :options="statusModel"
            label="label"
            v-model="filterModel.sale_status"
            :reduce="(status) => status.value"
            :multiple="true"
          />
        </div>
        <div class="col-md-2">
          <label for="Sale Tag">Sale Tag</label>
          <v-select
            :options="saleTagModel"
            label="name"
            v-model="filterModel.tags"
            :reduce="(tag) => tag.id"
            :multiple="true"
          />
        </div>
        <div class="col-md-2 my-3">
          <button id="" class="btn btn-secondary">Filter</button>
        </div>
        <div
          class="col-md-2"
          v-show="hasPermission(['limited_seller', 'seller', 'office'])"
        ></div>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-md-2"
          v-if="hasPermission(['limited_seller', 'seller'])"
        >
          <label for="storeInput">Store</label>
          <!-- <select id="storeInput" class="form-control" v-model="filterModel.store">
                        <option value="">All</option>
                        <option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">{{ s.store_name }}</option>
                    </select> -->
          <v-select
            :options="storeModel"
            label="store_name"
            v-model="filterModel.store"
            :reduce="(store) => store.id"
            :multiple="true"
          />
        </div>
        <div class="col-md-2" v-else></div>
        <div class="col-md-2">
          <label for="saleStatus">Seller</label>
          <!-- <select v-model="filterModel.seller" id="saleStatus" class="form-select">
                        <option value="">All</option>
                        <option v-for="seller in sellerModel" :value="seller.id" :key="seller.id">{{ seller.name }}
                        </option>
                    </select> -->
          <v-select
            :options="sellerModel"
            label="name"
            v-model="filterModel.seller"
            :reduce="(seller) => seller.id"
            :multiple="true"
          />

          <!-- <small>{{filterModel.seller}}</small> -->
        </div>

        <div class="col-md-2">
          <label for="customer">Customer</label>
          <SelectCustomer
            id="customerId"
            :customer-model="customerModel"
            v-model="filterModel.customer"
            :selected="filterModel.customer"
          >
          </SelectCustomer>
        </div>

        <div class="col-md-2">
          <label for="sale_type">Product Type</label>
          <select
            id="sale_type"
            v-model="filterModel.product_type"
            class="form-control"
          >
            <option value="">All</option>
            <option value="Jewel">Jewel</option>
            <option value="Watch">Watch</option>
            <option value="Jewel%26Watch">Jewel&Watch</option>
          </select>
        </div>
        <div class="col-md-1"></div>

        <!-- <div class="row">
            <p>{{}}</p>
        </div> -->
      </div>
      <div class="row mt-2">
        <hr />
      </div>

      <div class="row justify-content-center mt-2 mb-2">
        <div class="col-md-8">
          <table class="table table-bordered" v-if="showTable">
            <thead>
              <tr>
                <!-- <th>Count</th> -->
                <!-- <th>Total Sale</th> -->
                <th>Net Sale</th>
                <th>Total Expense</th>
                <th>Total Sale (Net + Expense)</th>
                <th>Total Tag Price</th>

                <th v-if="hasPermission(['limited_seller'])">Total Profit</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <!-- <td>{{saleCount}}</td> -->
                <!-- <td>{{formatCurrency(sumModel["total_sale"] + sumModel["total_expense"] || null )}}</td> -->
                <td>{{ formatCurrency(sumModel["total_sale"]) }}</td>
                <td>{{ formatCurrency(sumModel["total_expense"]) }}</td>
                <td>
                  {{
                    formatCurrency(
                      sumModel["total_sale"] + sumModel["total_expense"]
                    )
                  }}
                </td>
                <td>{{ formatCurrency(sumModel["total_ticket"]) }}</td>
                <td v-if="hasPermission(['limited_seller'])">
                  {{ formatCurrency(sumModel["total_profit"]) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-11">
          <keep-alive>
            <Table
              v-if="showTable"
              excelName="deneme"
              excelTabName="deneme"
              :tableInfo="tableInfo"
              showRoute="true"
              detailPath="/sales/"
              idName="sale_id"
              :useCreate="true"
              :key="tableKey"
              :endPoint="endPoint"
            />
          </keep-alive>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI } from "@/global/callAPI";
import Table from "@/components/Table";
import SelectCustomer from "@/components/SelectCustomer";
import { formatCurrency } from "@/global/functions";

export default {
  name: "CustomerList",
  components: { Menu, Table, SelectCustomer },

  data: function () {
    return {
      endPoint: null,
      tableKey: 0,
      showTable: false,
      customerModel: [],
      tagModel: [],
      sellerModel: [],
      saleCount: 0,
      totalNetSale: null,
      totalSale: null,
      totalExpense: null,
      totalProfit: null,
      sumModel: {},
      saleTagModel: [],
      tableInfo: [
        { field: "sale_reference", header: "Reference" },
        { field: "date", header: "Date", order: true, order: true },
        { field: "customer_name", header: "Customer" },
        { field: "seller", header: "Seller", order: true, order: true },
        { field: "store_name", header: "Store" },
        { field: "total_sale_price", header: "Sale Price", isCurrency: true },
        { field: "total_original_price", header: "Original Price" },
        { field: "total_expense", header: "Expense", isCurrency: true },
        { field: "balance", header: "Balance", isCurrency: true },

        { field: "sale_status", header: "Status" },
        { field: "tags", header: "Tags" },
      ],
      filterModel: {
        start_date: this.$route.query.start_date || null,
        end_date: this.$route.query.end_date || null,
        customer: this.$route.query.customer || null,
        // status:"sale__is_last=true&sale__is_return=false",
        sale_status: [],
        seller: [],
        // seller: this.$route.query.seller || null,
        product_type: this.$route.query.product_type || null,
        // store: this.$route.query.store || this.$store.state.store_id,
        store: [parseInt(this.$store.state.store_id)],

        tags: null,
      },
      sales: null,
      oldFilters: null,
      genderFilter: ["male", "female"],
      tagFilter: [],
      loading: false,
      tagInput: null,
      storeModel: [],
      customDateFilter: [
        {
          label: "All Time",
          value: "last_7_days",
          start_date: "2000-01-01",
          end_date: new Date(new Date().getFullYear(), 11, 31)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Last Month",
          value: "last_month",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            2
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Last 6 Months",
          value: "last_month",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 6,
            2
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Last Year",
          value: "last_year",
          start_date: new Date(new Date().getFullYear() - 1, 0, 2)
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), 0, 1)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "This Year",
          value: "this_year",
          start_date: new Date(new Date().getFullYear(), 0, 2)
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), 11, 31)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "This Month",
          value: "this_month",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            2
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            1
          )
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Today",
          value: "today",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
            .toISOString()
            .slice(0, 10),
        },
      ],

      statusModel: [
        { label: "All", value: "consignee,awaiting,completed,canceled" },
        { label: "Completed", value: "completed" },
        { label: "Consignee", value: "consignee" },
        { label: "Awaiting", value: "awaiting" },
        { label: "Canceled", value: "canceled" },
      ],
    };
  },
  // watch: {
  //     filterModel: {
  //     handler: function (newValue, oldValue) {
  //         this.$router.push({query: newValue})
  //     //     for (var key in newValue) {
  //     //         console.log(key,newValue[key])

  //     //         this.$router.push({
  //     //         query: {
  //     //             [key]: newValue[key]
  //     //         }
  //     //         })
  //     // }
  //     },
  //     deep: true
  //     },

  // },
  methods: {
    formatCurrency: formatCurrency,
    applyFilter() {
      if (this.filterModel.start_date === null) {
        window.alert("Please enter a start date!");
        return false;
      }
      let filterLi = this.filterModel;
      // if (filterLi["status"] === "sale__is_last=true&sale__is_return=false") {filterLi["status"] = null}

      const isEmpty = Object.values(filterLi).every(
        (x) => x === null || x === "" || x.length === 0
      );
      if (isEmpty === true) {
        window.alert("Please enter a filter!");
        return false;
      }

      let end_date = this.filterModel.end_date || "";
      let start_date = this.filterModel.start_date || "";
      let customer = this.filterModel.customer || "";
      let status = this.filterModel.status || "";
      let seller = this.filterModel.seller || "";
      let product_type = this.filterModel.product_type || "";
      let sale_status = this.filterModel.sale_status || "";
      let store = this.filterModel.store || "";
      let tags = this.filterModel.tags || "";

      if (sale_status.length === 0 || sale_status === null) {
        sale_status = this.statusModel.filter(
          (item) => item.label === "All"
        )[0]["value"];
      }
      let filterList = [
        `date__lte=${end_date}`,
        `date__gte=${start_date}`,
        `customer_id__id__in=${customer}`,
        `seller_id__id__in=${seller}`,
        `product_type=${product_type}`,
        `sale_status__in=${sale_status}`,
        `store_id__id__in=${store}`,
        "tags__tag__id__in=" + tags,

        // status,
      ];
      let query = filterList
        .join("&")
        .replaceAll("null", "")
        .replace("nbull", "null");

      getAPI("sum-sales?limit=1&" + query).then((res) => {
        this.sumModel = res.data;
      });

      this.loading = true;
      this.endPoint = "sale-list?" + query;
      this.tableKey += 1;
      this.showTable = true;
      // getAPI(`sales?`+ query)
      // .then(res => {
      //     this.sales = res.data.results;
      //     this.showTable =true
      //     this.calculator(res.data.results)
      //     });
    },
    calculator(value) {
      var netSale = 0;
      var sale = 0;
      var expense = 0;
      var profit = 0;

      for (const item of value) {
        netSale += item.total_sale_price;
        expense += item.total_expense;
        sale += item.total_sale_price + item.total_expense;
        profit += item.total_profit;
      }

      this.totalNetSale = netSale;
      this.totalSale = sale;
      this.totalExpense = expense;
      this.totalProfit = profit;
      this.saleCount = value.length || 0;
    },
    tagConverter(id) {
      return { tag: id };
    },
    applyCustomDateFilter(start_date, end_date) {
      this.filterModel.start_date = start_date;
      this.filterModel.end_date = end_date;
    },
    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
  },
  created: function () {
    getAPI("sale-tags?limit=10000").then((res) => {
      this.saleTagModel = res.data.results;
    });
    getAPI("stores?limit=10000&ordering=store_name&").then((res) => {
      this.storeModel = res.data.results;
    });

    let roles = ["limited_seller"];
    if (this.hasPermission((roles = roles))) {
      this.tableInfo.splice(7, 0, {
        field: "total_profit",
        header: "Profit",
        isCurrency: true,
      });
    }
    getAPI("user-name-list?limit=10000&ordering=name").then((res) => {
      this.sellerModel = res.data.results;
    });

    this.loading = true;
    this.oldFilters = this.filters;
  },
  // mounted: function () {

  //     this.applyFilter()
  // }
};
</script>

<style scoped>
input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

td {
  text-align: center;
  vertical-align: middle;
}

th {
  text-align: center;
  vertical-align: middle;
}
.custom-date-filter-button {
  margin-left: 3px;
  font-size: xx-small;
}
</style>
